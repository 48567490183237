<template>
  <div>
    <LocationWidget
      :widgetDetails="widgetDetails"
      :data="data"
      :chartConfigurations="chartConfigurations"
      :widgetTblConfigurations="widgetTblConfigurations"
      :formSubmitted="formSubmitted"
      :isEditPage="isEditPage"
      :defaultCulture="defaultCulture"
    />
    <template v-if="showLinkAccountFields">
      <b-form-row>
        <b-col>
          <b-form-group
            :class="{
              'is-invalid': !data.unlinkedWidgetTxt && formSubmitted,
            }"
          >
            <p>Unlinked widget text</p>
            <editor id="unlinked-widget-text" :api-key="tinyEditorOptions.apiKey" :init="tinyEditorCustomHeightOptions" v-model="data.unlinkedWidgetTxt" />
            <b-form-invalid-feedback class="d-block" v-if="!data.unlinkedWidgetTxt && formSubmitted">Unlinked widget text required.</b-form-invalid-feedback>
          </b-form-group>
        </b-col>
      </b-form-row>
      <b-form-row>
        <b-col>
          <b-form-group
            :class="{
              'is-invalid': !data.linkAccountTxt && formSubmitted,
            }"
          >
            <p>Text for linking account</p>
            <editor id="linking-account-text" :api-key="tinyEditorOptions.apiKey" :init="tinyEditorCustomHeightOptions" v-model="data.linkAccountTxt" />
            <b-form-invalid-feedback class="d-block" v-if="!data.linkAccountTxt && formSubmitted">Text for linking account required.</b-form-invalid-feedback>
          </b-form-group>
        </b-col>
      </b-form-row>
      <b-form-row>
        <b-col>
          <b-form-group
            :class="{
              'is-invalid': !data.unLinkAccountTxt && formSubmitted,
            }"
          >
            <p>Text for un-linking account</p>
            <editor
              id="unlinking-account-text"
              :api-key="tinyEditorOptions.apiKey"
              :init="tinyEditorCustomHeightOptions"
              height="100"
              v-model="data.unLinkAccountTxt"
            />
            <b-form-invalid-feedback class="d-block" v-if="!data.unLinkAccountTxt && formSubmitted"
              >Text for un-linking account required.</b-form-invalid-feedback
            >
          </b-form-group>
        </b-col>
      </b-form-row>
    </template>
  </div>
</template>
<script>
import Editor from '@tinymce/tinymce-vue'
import { TinyEditorOptions } from '../../../utilities/constants'
import cloneDeep from 'lodash/cloneDeep'
import { AUTHENTICATION_METHODS_ENUMS } from '@/utilities/widget-constants'
export default {
  name: 'IntegrationWidget',
  props: ['widgetDetails', 'data', 'tenantId', 'formSubmitted', 'isEditPage', 'chartConfigurations', 'widgetTblConfigurations', 'defaultCulture'],
  components: {
    editor: Editor,
    LocationWidget: () => import('./LocationWidget.vue'),
  },
  data() {
    return {
      tinyEditorOptions: TinyEditorOptions,
    }
  },
  computed: {
    showLinkAccountFields() {
      return this.data.authenticationType === AUTHENTICATION_METHODS_ENUMS.LINK_ACCOUNT
    },
    tinyEditorCustomHeightOptions() {
      const config = cloneDeep(this.tinyEditorOptions.initConfig)
      config.height = 200
      return config
    },
  },
}
</script>
